import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import MultiAxisChart from "examples/Charts/MultiAxisChart";
import Footer from "examples/Footer";

import { serverBaseURL } from "common/settings";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function EnvironmentalValue() {
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [impactAndCumulativePowerGraphData, setImpactAndCumulativePowerGraphData] = useState({});
  const [impactGraphData, setImpactGraphData] = useState({});
  const { getToken } = useKindeAuth();

  async function fetchSiteImpact(siteId) {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}environmentalvalues/?site_id=${siteId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
    console.log(response);
    const lifetimeYears = response.lifetime_years;
    setImpactAndCumulativePowerGraphData({
      labels: ["10", "15", "30", lifetimeYears.toString()],
      datasets1: [
        {
          label: "Environmental Impact per Functional Unit (mPt/kWh)",
          data: response.environmental_impacts,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        {
          label: "Junction box repair",
          data: response.junctionbox_repair,
          backgroundColor: "rgba(0, 255, 255, 0.8)",
        },
        {
          label: "Transport 100km",
          data: response.transport_100km,
          backgroundColor: "rgba(255, 0, 255, 0.8)",
        },
        {
          label: "Transport 10000km",
          data: response.transport_10000km,
          backgroundColor: "rgba(255, 255, 0, 0.8)",
        },
      ],
      datasets2: [
        {
          label: "Cumulative power",
          data: response.cumulative_power,
          backgroundColor: "rgba(75, 255, 255, 0.8)",
        },
      ],
    });
    setImpactGraphData({
      labels: ["10", "15", "30"],
      datasets: [
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 1.4%/year, No reduction in EI production and recycle)",
          data: response.environmental_impacts,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          stack: "Stack 0",
        },
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 1.4%/year, EI production and recycle reduces by 10% every 5 years)",
          data: response.environmental_impacts_2,
          backgroundColor: "rgba(0, 255, 255, 0.8)",
          stack: "Stack 1",
        },
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 2.8%/year, No reduction in EI production and recycle)",
          data: response.environmental_impacts_3,
          backgroundColor: "rgba(255, 0, 255, 0.8)",
          stack: "Stack 2",
        },
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 2.8%/year, EI production and recycle reduces by 10% every 5 years)",
          data: response.environmental_impacts_2,
          backgroundColor: "rgba(255, 255, 0, 0.8)",
          stack: "Stack 3",
        },
      ],
    });
  }

  function selectChange(event) {
    setSelectedSite(event.target.value);
    fetchSiteImpact(event.target.value);
  }

  async function fetchSites() {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}sites`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
    setSites(response);
  }

  useEffect(() => {
    fetchSites();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <br />
        <MDBox p={5}>
          <FormControl fullWidth>
            <InputLabel id="sites-label">Sites</InputLabel>
            <Select
              labelId="sites-label"
              id="sites-select"
              value={selectedSite}
              label="Select Site"
              sx={{ minHeight: "50px", maxWidth: "200px" }}
              onChange={selectChange}
            >
              {sites.map((site, index) => (
                <MenuItem key={index} value={site.id}>
                  {site.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
        <MDBox mt={5}>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5} mt={1.5}>
                <MultiAxisChart
                  icon={{ component: "show_chart" }}
                  title="Panel service life and cumulative power output"
                  description="&nbsp;"
                  chart={impactAndCumulativePowerGraphData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5} mt={1.5}>
                <ReportsBarChart
                  icon={{ component: "show_chart" }}
                  title="Panel service life"
                  description="&nbsp;"
                  chart={impactGraphData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EnvironmentalValue;
