import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GoogleMap, LoadScript, Marker, DistanceMatrixService } from "@react-google-maps/api";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import MultiAxisChart from "examples/Charts/MultiAxisChart";
import FormField from "components/FormField";

import translation from "layouts/ongrid-dashboards/power-generation-forecast/data/translation";

import { serverBaseURL } from "common/settings";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const center = {
  lat: 1.29,
  lng: 103.85,
};

function GeoMap({ siteMarkers, clickedMarkers, onClick, lat, lng, siteLat, siteLng, setDistance }) {
  return (
    <LoadScript googleMapsApiKey="AIzaSyCMRlsJIkTmawGuiXktiAnHHWCm3Axt7r8">
      <GoogleMap
        zoom={10}
        center={center}
        options={{ scrollwheel: false }}
        onClick={onClick}
        mapContainerStyle={{ height: `560px`, borderRadius: "6px", overflow: "hidden" }}
      >
        {siteMarkers}
        {clickedMarkers}
        <DistanceMatrixService
          options={{
            destinations: [{ lat: lat, lng: lng }],
            origins: [{ lat: siteLat, lng: siteLng }],
            travelMode: "DRIVING",
          }}
          callback={(response) => {
            const distance = (response.rows[0].elements[0].distance?.value ?? 0) / 1000;
            setDistance(distance);
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
}

GeoMap.propTypes = {
  siteMarkers: PropTypes.arrayOf(PropTypes.object).isRequired,
  clickedMarkers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  siteLat: PropTypes.number.isRequired,
  siteLng: PropTypes.number.isRequired,
  setDistance: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      mt={3}
      justifyContent="center"
      {...other}
    >
      {value === index && children}
    </Stack>
  );
}

TabPanel.defaultProps = {
  children: "",
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function getSteps() {
  return [
    "How to use",
    <>
      User guide <br /> A grid connected
    </>,
    <>
      User guide <br /> An off-grid
    </>,
  ];
}

function annuity(numPayments, interestRate, systemPrice) {
  const modifiedInterestRate = 1.0 + 0.01 * interestRate;
  let sum = 1;
  let fac = 1.0;
  for (let i = 0; i < numPayments - 1; i += 1) {
    fac *= modifiedInterestRate;
    sum += fac;
  }
  return (systemPrice * fac * modifiedInterestRate) / sum;
}

function getStepContent(stepIndex, currentTranslation) {
  switch (stepIndex) {
    case 0:
      return (
        <MDBox mx={5}>
          <MDTypography variant="body2">{currentTranslation.tutorial1}</MDTypography>
        </MDBox>
      );
    case 1:
      return (
        <MDBox mx={5}>
          <MDTypography variant="body2">{currentTranslation.tutorial2}</MDTypography>
        </MDBox>
      );
    case 2:
      return (
        <MDBox mx={5}>
          <MDTypography variant="body2">{currentTranslation.tutorial3}</MDTypography>
        </MDBox>
      );
    default:
      return null;
  }
}

function PowerGenerationForecast() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleForward = () => setActiveStep(activeStep + 1);
  const isLastStep = activeStep === steps.length - 1;

  const currentTranslation = translation.EN;

  const { getToken } = useKindeAuth();

  const [siteMarkers, setSiteMarkers] = useState([]);
  const [clickedMarkers, setClickedMarkers] = useState([]);
  const [pvprice, setPvprice] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [databaseDisabled, setDatabaseDisabled] = useState(true);
  const [systemCostDisabled, setSystemCostDisabled] = useState(true);
  const [interestDisabled, setInterestDisabled] = useState(true);
  const [lifetimeDisabled, setLifetimeDisabled] = useState(true);
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [database, setDatabase] = useState(null);
  const [inputDatabase, setInputDatabase] = useState("");
  const [peakPVPower, setPeakPVPower] = useState(1);
  const [systemLoss, setSystemLoss] = useState(14);
  const [slope, setSlope] = useState(35);
  const [azimuth, setAzimuth] = useState(0);
  const [impactAndCumulativePowerGraphData, setImpactAndCumulativePowerGraphData] = useState({});
  const [monthlyGraphData, setMonthlyGraphData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "", data: [] },
  });
  const [percentileGraphData, setPercentileGraphData] = useState({
    labels: [],
    datasets: { label: "", data: [] },
  });
  const [slopeAngle, setSlopeAngle] = useState(0);
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [yearlyPVEnergyProduction, setYearlyPVEnergyProduction] = useState(0);
  const [yearlyInplaneIrradiation, setYearlyInplaneIrradiation] = useState(0);
  const [yearToYearVariability, setYearToYearVariability] = useState(0);
  const [angleOfIncidence, setAngleOfIncidence] = useState(0);
  const [spectralEffects, setSpectralEffects] = useState(0);
  const [temperatureAndLowIrradiance, setTemperatureAndLowIrradiance] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [systemCost, setSystemCost] = useState("");
  const [interest, setInterest] = useState("");
  const [lifetime, setLifetime] = useState("");
  const [pvElectricityCost, setPVElectricityCost] = useState("");

  const [offGridPeakPVPower, setOffGridPeakPVPower] = useState(50);
  const [offGridBatteryCapacity, setOffGridBatteryCapacity] = useState(600);
  const [offGridDischargeCutoffLimit, setOffGridDischargeCutoffLimit] = useState(40);
  const [offGridConsumptionPerDay, setOffGridConsumptionPerDay] = useState(300);
  const [offGridSlope, setOffGridSlop] = useState(35);
  const [offGridAzimuth, setOffGridAzimuth] = useState(0);

  const [offGridFullBatteryDays, setOffGridFullBatteryDays] = useState(0);
  const [offGridEmptyBatteryDays, setOffGridEmptyBatteryDays] = useState(0);
  const [offGridAverageEnergyNotCaptured, setOffGirdAverageEnergyNotCaptured] = useState(0);
  const [offGridAverageEnergyMissing, setOffGridAverageEnergyMissing] = useState(0);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [siteLat, setSiteLat] = useState(0);
  const [siteLng, setSiteLng] = useState(0);
  const [distance, setDistance] = useState(0);

  const [pvTechnologyOptions, setPVTechnologyOptions] = useState([
    {
      label: "Crystalline silicon",
      code: "crystSi",
    },
    {
      label: "CIS",
      code: "CIS",
    },
    {
      label: "CdTe",
      code: "CdTe",
    },
    {
      label: currentTranslation.pv_technology_dropdown_unknown,
      code: "Unknown",
    },
  ]);
  const [pvTechnology, setPVTechnology] = useState(pvTechnologyOptions[0]);
  const [inputPVTechnology, setInputPVTechnology] = useState("");
  const [mountingPositionOptions, setMountingPositionOptions] = useState([
    {
      label: currentTranslation.mounting_position_dropdown_free_standing,
      code: "free",
    },
    {
      label: currentTranslation.mounting_position_dropdown_roof_added_building_integrated,
      code: "building",
    },
  ]);
  const [mountingPosition, setMountingPosition] = useState(mountingPositionOptions[0]);
  const [intputMountingPosition, setInputMountingPosition] = useState("");
  const [optimizeOptions, setOptimizeOptions] = useState([
    {
      label: currentTranslation.optimize_dropdown_none,
      code: "n",
    },
    {
      label: currentTranslation.optimize_dropdown_slope,
      code: "s",
    },
    {
      label: currentTranslation.optimize_dropdown_slope_and_azimuth,
      code: "sa",
    },
  ]);
  const [optimize, setOptimize] = useState(optimizeOptions[0]);
  const [inputOptimize, setInputOptimize] = useState("");
  const [impactResponse, setImpactResponse] = useState({});

  const databases = ["PVGIS-SARAH2", "PVGIS-SARAH", "PVGIS-NSRDB", "PVGIS-ERA5"];

  const textfield = {
    "& .MuiInputBase-input.MuiAutocomplete-input": {
      color: "#ffffff",
    },
  };

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  async function fetchSiteImpact(siteId) {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}environmentalvalues/?site_id=${siteId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
    console.log(response);
    console.log(response.transport_100km);
    setImpactResponse(response);
    const lifetimeYears = response.lifetime_years;
    setImpactAndCumulativePowerGraphData({
      labels: ["10", "15", "30", lifetimeYears.toString()],
      datasets1: [
        {
          label: "Environmental Impact per Functional Unit (mPt/kWh)",
          data: response.environmental_impacts,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        {
          label: "Junction box repair",
          data: response.junctionbox_repair,
          backgroundColor: "rgba(0, 255, 255, 0.8)",
        },
        {
          label: "Transport",
          data: response.transport_100km.map((x) => x * 0),
          backgroundColor: "rgba(255, 0, 255, 0.8)",
        },
      ],
      datasets2: [
        {
          label: "Cumulative power",
          data: response.cumulative_power,
          backgroundColor: "rgba(75, 255, 255, 0.8)",
        },
      ],
    });
    /*
    setImpactGraphData({
      labels: ["10", "15", "30"],
      datasets: [
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 1.4%/year, No reduction in EI production and recycle)",
          data: response.environmental_impacts,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          stack: "Stack 0",
        },
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 1.4%/year, EI production and recycle reduces by 10% every 5 years)",
          data: response.environmental_impacts_2,
          backgroundColor: "rgba(0, 255, 255, 0.8)",
          stack: "Stack 1",
        },
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 2.8%/year, No reduction in EI production and recycle)",
          data: response.environmental_impacts_3,
          backgroundColor: "rgba(255, 0, 255, 0.8)",
          stack: "Stack 2",
        },
        {
          label:
            "Environmental Impact per Functional Unit (mPt/kWh) (Peak power improves 2.8%/year, EI production and recycle reduces by 10% every 5 years)",
          data: response.environmental_impacts_2,
          backgroundColor: "rgba(255, 255, 0, 0.8)",
          stack: "Stack 3",
        },
      ],
    });
    */
  }

  const mapClicked = async (event) => {
    const { latLng } = event;
    const clickedLat = Number(latLng.lat().toFixed(3));
    const clickedLng = Number(latLng.lng().toFixed(3));

    setClickedMarkers([
      <Marker position={{ lat: clickedLat, lng: clickedLng }} key="clickedCoord" />,
    ]);
    /*
    const currentSite = sites.filter((site) => site.id === selectedSite)[0];
    const coordinate = currentSite.coordinate;
    const [tempLat, tempLon] = coordinate.split(",");
    const siteLat = Number(tempLat);
    const siteLon = Number(tempLon);
    */

    const elevationResponse = await (
      await fetch(`${serverBaseURL}getelevation?lat=${clickedLat}&lon=${clickedLng}`, {
        method: "GET",
      })
    ).text();
    const elevation = Number(elevationResponse);
    if (elevation < -500) {
      return;
    }
    const extentResponse = await (
      await fetch(`${serverBaseURL}extent?lat=${clickedLat}&lon=${clickedLng}`, {
        method: "GET",
      })
    ).text();
    const extents = extentResponse
      .split("\n")
      .filter((e) => e !== "")
      .map((e) => Number(e));
    const useableDatabases = databases.filter((e, i) => extents[i] === 1);
    setLat(clickedLat);
    setLng(clickedLng);
    setDatabaseDisabled(false);
    setDatabaseOptions(useableDatabases);
    setDatabase(useableDatabases[0]);
  };

  const caculateOnGrid = async () => {
    let optimizeParameters = "";

    if (optimize.code === "n") {
      optimizeParameters = `angle=${slope}&aspect=${azimuth}`;
    } else if (optimize.code === "s") {
      optimizeParameters = `optimalinclination=1&aspect=${azimuth}`;
    } else if (optimize.code === "sa") {
      optimizeParameters = `optimalangles=1`;
    }

    const pvcalcResponse = await (
      await fetch(
        `${serverBaseURL}pv_calc?outputformat=basic&lat=${lat}&lon=${lng}&raddatabase=${database}&peakpower=${peakPVPower}&loss=${systemLoss}&mountingplace=${mountingPosition.code}&pvtechchoice=${pvTechnology.code}&${optimizeParameters}&usehorizon=1&userhorizon=`,
        {
          method: "GET",
        }
      )
    ).text();
    const pvcalcLines = pvcalcResponse.split("\r\n");
    const monthlyData = pvcalcLines.slice(2, 14).map((i) => i.split("\t").slice(1, 4));
    const monthlyPvoutput = monthlyData.map((i) => i[0]);
    const monthlyRadiation = monthlyData.map((i) => i[1]);
    setMonthlyGraphData({
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: currentTranslation.monthly_performance_graph_pv_output,
          data: monthlyPvoutput,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        {
          label: currentTranslation.monthly_performance_graph_radiation,
          data: monthlyRadiation,
          backgroundColor: "rgba(0, 255, 255, 0.8)",
        },
      ],
    });
    setSlopeAngle(Number(pvcalcLines[0]));
    setAzimuthAngle(Number(pvcalcLines[1]));
    const yearlyData = pvcalcLines[14].split("\t");
    setYearlyPVEnergyProduction(Number(yearlyData[1]));
    setYearlyInplaneIrradiation(Number(yearlyData[2]));
    setYearToYearVariability(Number(yearlyData[3]));
    setAngleOfIncidence(Number(pvcalcLines[15].split("\t")[1]));
    setSpectralEffects(Number(pvcalcLines[16].split("\t")[1]) || pvcalcLines[16].split("\t")[1]);
    setTemperatureAndLowIrradiance(Number(pvcalcLines[17].split("\t")[1]));
    setTotalLoss(Number(pvcalcLines[18].split("\t")[1]));

    if (pvprice) {
      if (Number.isNaN(systemCost) || Number.isNaN(interest) || Number.isNaN(lifetime)) {
        setPVElectricityCost("");
      } else {
        setPVElectricityCost(
          (annuity(lifetime, interest, systemCost) + 0.02 * systemCost).toFixed(3)
        );
      }
    }
  };

  const caculateOffGrid = async () => {
    const shscalcResponse = await (
      await fetch(
        `${serverBaseURL}shs_calc?outputformat=basic&lat=${lat}&lon=${lng}&raddatabase=${database}&peakpower=${offGridPeakPVPower}&batterysize=${offGridBatteryCapacity}&cutoff=${offGridDischargeCutoffLimit}&consumptionday=${offGridConsumptionPerDay}&angle=${offGridSlope}&aspect=${offGridAzimuth}&usehorizon=1&userhorizon=&hourconsumption=`,
        {
          method: "GET",
        }
      )
    ).text();
    const shscalcLines = shscalcResponse.split("\r\n");
    const monthlyData = shscalcLines.slice(5, 17).map((i) => i.split("\t").slice(1, 5));
    const batteryState = shscalcLines.slice(17, 28).map((i) => i.split("\t"));
    const monthlyEnergyOutput = monthlyData.map((i) => i[0]);
    const mothlyEnergyNotCaptured = monthlyData.map((i) => i[1]);
    const monthlyDaysFull = monthlyData.map((i) => i[2]);
    const monthlyDaysEmpty = monthlyData.map((i) => i[3]);
    setMonthlyGraphData({
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: currentTranslation.monthly_performance_graph_energy_output,
          data: monthlyEnergyOutput,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        {
          label: currentTranslation.monthly_performance_graph_energy_not_captured,
          data: mothlyEnergyNotCaptured,
          backgroundColor: "rgba(0, 255, 255, 0.8)",
        },
        {
          label: currentTranslation.monthly_performance_graph_days_with_battery_full,
          data: monthlyDaysFull,
          backgroundColor: "rgba(255, 0, 255, 0.8)",
        },
        {
          label: currentTranslation.monthly_performance_graph_days_with_battery_empty,
          data: monthlyDaysEmpty,
          backgroundColor: "rgba(255, 255, 0, 0.8)",
        },
      ],
    });
    const percentileGraphLabel = batteryState.map(
      (i) => `${Number(i[0]).toFixed(0)}-${Number(i[1]).toFixed(0)}`
    );
    const percentileData = batteryState.map((i) => i[2]);
    setPercentileGraphData({
      labels: percentileGraphLabel,
      datasets: {
        label: currentTranslation.percentile_graph_charge_state_frequency,
        data: percentileData,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    });
    setOffGridFullBatteryDays(shscalcLines[1]);
    setOffGridEmptyBatteryDays(shscalcLines[3]);
    setOffGirdAverageEnergyNotCaptured(shscalcLines[2]);
    setOffGridAverageEnergyMissing(shscalcLines[4]);
  };

  async function fetchPanelResult(siteId) {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}panel_results/${siteId}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
    setLifetime(parseInt(response.lifetime_rates.length));
    const currentSite = sites.filter((site) => site.id === siteId)[0];
    const coordinate = currentSite.coordinate;
    const [tempLat, tempLon] = coordinate.split(",");
    const siteLat = Number(tempLat);
    const siteLon = Number(tempLon);
    setSiteLat(siteLat);
    setSiteLng(siteLon);
    setSiteMarkers([
      <Marker
        position={{ lat: siteLat, lng: siteLon }}
        key="siteCoord"
        icon={"http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"}
      />,
    ]);
    setSlope(currentSite.tilt);
    setAzimuth(currentSite.azimuth);
    setPeakPVPower(currentSite.panel_output);
    setSystemLoss(Math.ceil(Math.abs(response.exceedance_level) * 100) + 14);
  }

  async function fetchSites() {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}sites`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
    setSites(response);
  }

  // async function

  function selectChange(event) {
    setSelectedSite(event.target.value);
    fetchPanelResult(event.target.value);
    fetchSiteImpact(event.target.value);
  }

  useEffect(() => {
    setPVTechnology(pvTechnologyOptions[0]);
    setInputPVTechnology("");
  }, [pvTechnologyOptions]);

  useEffect(() => {
    setMountingPosition(mountingPositionOptions[0]);
    setInputMountingPosition("");
  }, [mountingPositionOptions]);

  useEffect(() => {
    setOptimize(optimizeOptions[0]);
    setInputOptimize("");
  }, [optimizeOptions]);

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    const lifetimeYears = impactResponse.lifetime_years ?? 0;
    setImpactAndCumulativePowerGraphData({
      labels: ["10", "15", "30", lifetimeYears.toString()],
      datasets1: [
        {
          label: "Environmental Impact per Functional Unit (mPt/kWh)",
          data: impactResponse.environmental_impacts,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        {
          label: "Junction box repair",
          data: impactResponse.junctionbox_repair,
          backgroundColor: "rgba(0, 255, 255, 0.8)",
        },
        {
          label: "Transport",
          data: impactResponse.transport_100km?.map((x) => x * (distance / 100)),
          backgroundColor: "rgba(255, 0, 255, 0.8)",
        },
      ],
      datasets2: [
        {
          label: "Cumulative power",
          data: impactResponse.cumulative_power,
          backgroundColor: "rgba(75, 255, 255, 0.8)",
        },
      ],
    });
  }, [distance]);

  const valueCompare = (option, newValue) => option.code === newValue.code;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <MDBox mt={8} ml={5} mr={5}>
          <Card sx={{ height: "100%" }}>
            <MDBox mx={2} mt={-3}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <MDBox p={3}>
              <MDBox>
                {getStepContent(activeStep, currentTranslation)}
                <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton variant="gradient" color="light" onClick={handleBack}>
                      back
                    </MDButton>
                  )}
                  {isLastStep ? (
                    <MDBox />
                  ) : (
                    <MDButton type="submit" variant="gradient" color="dark" onClick={handleForward}>
                      next
                    </MDButton>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox p={5}>
          <FormControl fullWidth>
            <InputLabel id="sites-label">Sites</InputLabel>
            <Select
              labelId="sites-label"
              id="sites-select"
              value={selectedSite}
              label="Select Site"
              sx={{ minHeight: "50px", maxWidth: "200px" }}
              onChange={selectChange}
            >
              {sites.map((site, index) => (
                <MenuItem key={index} value={site.id}>
                  {site.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
        <MDBox py={3}>
          <MDBox mt={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={3}>
                <Card sx={{ position: "relative", mt: -8, mx: 3, py: 2, px: 4 }}>
                  <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                    <Tab label={currentTranslation.grid_connected} />
                    <Tab label={currentTranslation.off_grid} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <MDTypography align="center" variant="h5">
                      {currentTranslation.input}
                    </MDTypography>
                    <MDBox mb={3} mt={3}>
                      <Autocomplete
                        disabled={databaseDisabled}
                        value={database}
                        onChange={(e, v) => setDatabase(v)}
                        inputValue={inputDatabase}
                        onInputChange={(e, v) => setInputDatabase(v)}
                        options={databaseOptions}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label={currentTranslation.solar_radiation_database}
                          />
                        )}
                      />
                    </MDBox>
                    <MDBox mb={3}>
                      <Autocomplete
                        value={pvTechnology}
                        onChange={(e, v) => setPVTechnology(v)}
                        inputValue={inputPVTechnology}
                        onInputChange={(e, v) => setInputPVTechnology(v)}
                        options={pvTechnologyOptions}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label={currentTranslation.pv_technology}
                          />
                        )}
                        isOptionEqualToValue={valueCompare}
                      />
                    </MDBox>
                    <FormField
                      type="number"
                      label={currentTranslation.installed_peak_pv_power_kwp}
                      value={peakPVPower}
                      onChange={(e) => setPeakPVPower(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.system_loss}
                      value={systemLoss}
                      onChange={(e) => setSystemLoss(e.target.value)}
                    />
                    <Divider />
                    <MDBox mb={1.625} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        {currentTranslation.fixed_mounting_options}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={3}>
                      <Autocomplete
                        value={mountingPosition}
                        onChange={(e, v) => setMountingPosition(v)}
                        inputValue={intputMountingPosition}
                        onInputChange={(e, v) => setInputMountingPosition(v)}
                        options={mountingPositionOptions}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label={currentTranslation.mounting_position}
                          />
                        )}
                        isOptionEqualToValue={valueCompare}
                      />
                    </MDBox>
                    <FormField
                      type="number"
                      label={currentTranslation.slope}
                      value={slope}
                      onChange={(e) => setSlope(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.azimuth}
                      value={azimuth}
                      onChange={(e) => setAzimuth(e.target.value)}
                    />
                    <MDBox mb={3}>
                      <Autocomplete
                        value={optimize}
                        onChange={(e, v) => setOptimize(v)}
                        inputValue={inputOptimize}
                        onInputChange={(e, v) => setInputOptimize(v)}
                        options={optimizeOptions}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label={currentTranslation.optimize}
                          />
                        )}
                        isOptionEqualToValue={valueCompare}
                      />
                    </MDBox>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDBox mt={0.5}>
                        <Switch
                          checked={pvprice}
                          onChange={() => {
                            setPvprice(!pvprice);
                            setSystemCostDisabled(pvprice);
                            setInterestDisabled(pvprice);
                            setLifetimeDisabled(pvprice);
                          }}
                        />
                      </MDBox>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {currentTranslation.pv_electricity_price}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <FormField
                      type="number"
                      label={currentTranslation.pv_system_cose}
                      defaultValue={systemCost}
                      disabled={systemCostDisabled}
                      onChange={(e) => setSystemCost(Number(e.target.value))}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.interest}
                      defaultValue={interest}
                      disabled={interestDisabled}
                      onChange={(e) => setInterest(Number(e.target.value))}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.lifetime}
                      value={lifetime}
                      disabled={lifetimeDisabled}
                      onChange={(e) => setLifetime(Number(e.target.value))}
                    />
                    <MDButton variant="gradient" color="dark" onClick={caculateOnGrid}>
                      {currentTranslation.visualize_result}
                    </MDButton>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <MDTypography align="center" variant="h5">
                      {currentTranslation.input}
                    </MDTypography>
                    <MDBox mb={3} mt={3}>
                      <Autocomplete
                        disabled={databaseDisabled}
                        value={database}
                        onChange={(e, v) => setDatabase(v)}
                        inputValue={inputDatabase}
                        onInputChange={(e, v) => setInputDatabase(v)}
                        options={databaseOptions}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label={currentTranslation.solar_radiation_database}
                          />
                        )}
                      />
                    </MDBox>
                    <FormField
                      type="number"
                      label={currentTranslation.installed_peak_pv_power_wp}
                      defaultValue={offGridPeakPVPower}
                      onChange={(e) => setOffGridPeakPVPower(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.battery_capacity}
                      defaultValue={offGridBatteryCapacity}
                      onChange={(e) => setOffGridBatteryCapacity(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.discharge_cutoff_limit}
                      defaultValue={offGridDischargeCutoffLimit}
                      onChange={(e) => setOffGridDischargeCutoffLimit(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.consumption_per_day}
                      defaultValue={offGridConsumptionPerDay}
                      onChange={(e) => setOffGridConsumptionPerDay(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.slope}
                      defaultValue={offGridSlope}
                      onChange={(e) => setOffGridSlop(e.target.value)}
                    />
                    <FormField
                      type="number"
                      label={currentTranslation.azimuth}
                      defaultValue={offGridAzimuth}
                      onChange={(e) => setOffGridAzimuth(e.target.value)}
                    />
                    <MDButton variant="gradient" color="dark" onClick={caculateOffGrid}>
                      {currentTranslation.visualize_result}
                    </MDButton>
                  </TabPanel>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={9}>
                <MDBox mb={3} mt={-7.8}>
                  <GeoMap
                    siteMarkers={siteMarkers}
                    clickedMarkers={clickedMarkers}
                    onClick={mapClicked}
                    lat={lat}
                    lng={lng}
                    siteLat={siteLat}
                    siteLng={siteLng}
                    setDistance={setDistance}
                  />
                </MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox mt={10}>
                      {tabValue === 0 ? (
                        <Card sx={{ position: "relative", mt: -8, py: 2, px: 4 }}>
                          <MDTypography align="center" variant="h5" mb={6}>
                            {currentTranslation.simulation_outputs}
                          </MDTypography>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.slope_angle}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {slopeAngle}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.azimuth_angle}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {azimuthAngle}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.yearly_pv_energy_production}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {yearlyPVEnergyProduction}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.yearly_in_plane_irradiation}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {yearlyInplaneIrradiation}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.year_to_year_variability}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {yearToYearVariability}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.changes_in_output_due_to}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {" "}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;&nbsp;{currentTranslation.angle_of_incidence}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {angleOfIncidence}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;&nbsp;{currentTranslation.spectral_effects}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {spectralEffects}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;&nbsp;{currentTranslation.temperature_and_low_irradiance}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {temperatureAndLowIrradiance}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.total_loss}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {totalLoss}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.pv_electricity_cost}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {pvElectricityCost}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Card>
                      ) : (
                        <Card sx={{ position: "relative", mt: -8, py: 2, px: 4 }}>
                          <MDTypography align="center" variant="h5" mb={6}>
                            {currentTranslation.simulation_outputs}
                          </MDTypography>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.percentage_days_with_full_battery}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {offGridFullBatteryDays}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.percentage_days_with_empty_battery}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {offGridEmptyBatteryDays}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.average_energy_not_captured}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {offGridAverageEnergyNotCaptured}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              {currentTranslation.average_energy_missing}:
                            </MDTypography>
                            <MDBox ml={1}>
                              <MDTypography variant="body2" fontWeight="medium">
                                {offGridAverageEnergyMissing}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Card>
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox mt={2}>
                      <ReportsBarChart
                        color="info"
                        date=""
                        title={currentTranslation.monthly_performance}
                        chart={monthlyGraphData}
                      />
                    </MDBox>
                    {tabValue === 1 ? (
                      <MDBox mt={6}>
                        <ReportsBarChart
                          color="success"
                          date=""
                          title={currentTranslation.percentile}
                          chart={percentileGraphData}
                        />
                      </MDBox>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <MDBox mt={5}>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <MDBox mb={1.5} mt={1.5}>
                    <MultiAxisChart
                      icon={{ component: "show_chart" }}
                      title="Panel service life and cumulative power output"
                      description="&nbsp;"
                      chart={impactAndCumulativePowerGraphData}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          {/* }
          <MDBox mt={1.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mt={3}>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mt={3}>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mt={3}>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          { */}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default PowerGenerationForecast;
